body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  /* font-family: "helvetica-neue", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #efefef;
  height: "100%";
  width: auto !important;
  overflow-x: hidden !important;
  overflow-y: scroll;
  cursor: pointer !important;
  background-color: #ffffff;
  margin: 0;
}

@media screen and (min-width: 800px) {
  body {
    /* padding-top: 94px; */
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  input {
    font-size: 16px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .form-control {
    font-size: 16px;
  }
}

.form-control:focus {
  border-color: #ccc;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.html {
  height: 100% !important;
}

.body {
  height: 100% !important;
  margin: 0;
}

.videoDisplay::-webkit-scrollbar {
  display: none;
}

.videoDisplay {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#basic-navbar-nav:focus {
  z-index: 10;
  border-color: #ffffff !important;
  outline: 0 !important;
  background-color: #232f3e !important;
}

#basic-nav-dropdown {
  color: white;
  font-weight: bold;
  background-color: #232f3e;
}

.active.page-link {
  background: #232f3e !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.VideoInput {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  margin-bottom: 100px;
}

.VideoInput_input {
  display: none;
}

.VideoInput_video {
  display: block;
  margin: 0;
}

.VideoInput_footer {
  overflow-wrap: break-word;
  background: #eee;
  width: 100%;
  min-height: 40px;
  line-height: 40px;
  text-align: center;
  margin-top: 20px;
}

.clickable {
  cursor: pointer;
}

form-control:focus {
  border-color: #ccc;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

textarea {
  /* iOS Safari */
  -webkit-touch-callout: auto;
  /* Safari */
  -webkit-user-select: auto;
  /* Konqueror HTML */
  -khtml-user-select: auto;
  /* Firefox */
  -moz-user-select: auto;
  /* Internet Explorer/Edge */
  -ms-user-select: auto;
  /* Non-prefixed version, currently supported by Chrome and Opera */
  user-select: auto;
}

Image {
  border: none;
  display: block;
  transform: translateZ(0) scale(0.999999);
}

.commentLink:hover {
  text-decoration: underline !important;
  color: "brown";
}

.profile-picture-uploader {
  text-align: center;
}

.file-input {
  display: none;
}

.preview-image {
  height: auto !important;
  width: auto !important;
  max-width: 200px !important;
  max-height: 200px !important;
  margin-top: 10px;
}

.landscape-photo-grid {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(150px, 1fr)
  ); /* Adjust the minimum width of each column as needed */
  grid-auto-rows: 100px; /* Adjust the height of each row as needed */
  grid-gap: 10px; /* Adjust the gap between photos as needed */
}

.landscape-photo {
  overflow: hidden; /* Ensure that photos don't exceed their container */
}

.landscape-photo img {
  width: 100%; /* Ensure that photos fill their container */
  height: 100%; /* Ensure that photos maintain their aspect ratio */
  object-fit: cover; /* Cover the entire container with the photo */
}

.image-grid {
  --gap: 8px;
  --num-cols: 6;
  --row-height: 250px;

  box-sizing: border-box;
  padding: var(--gap);

  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  grid-auto-rows: var(--row-height);
  gap: var(--gap);
}

/* @media screen and (max-width: 1024px) {
  .image-grid {
    --num-cols: 2;
    --row-height: 200px;
  }
} */

.image-grid > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-grid-col-2 {
  grid-column: span 4;
}

.image-grid-col-3 {
  grid-column: span 2;
}

.image-grid-row-2 {
  grid-row: span 2;
}

@media screen and (max-width: 700px) {
  .image-grid {
    --num-cols: 4;
    --row-height: 100px;
  }
}

@media screen and (min-width: 1284px) {
  .image-grid {
    --num-cols: 2;
    --row-height: 200px;
  }
}

@media screen and (min-width: 1040px) {
  .image-grid {
    --num-cols: 6;
    --row-height: 300px;
  }
}

.image-button {
  position: absolute;
  top: 65%;
  left: 31%;
  transform: translate(-65%, -31%);
  padding: 10px 20px;
  background-color: #ffffff;
  color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1;
  marginright: 4;
}

.image-map {
  position: absolute;
  top: 65%;
  left: 55%;
  transform: translate(-65%, -31%);
  padding: 10px 20px;
  background-color: #ffffff;
  color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1;
  marginleft: 4;
}

@media screen and (max-width: 1024px) {
  .image-map {
    position: absolute;
    top: 58%;
    left: 40%;
    transform: translate(-58%, -40%);
    padding: 10px 20px;
    background-color: #ffffff;
    color: #000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    z-index: 1;
    marginleft: 4;
  }
}

@media screen and (max-width: 1024px) {
  .image-button {
    position: absolute;
    top: 66%;
    left: 46%;
    transform: translate(-66%, -46%);
    padding: 10px 20px;
    background-color: #ffffff;
    color: #000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    z-index: 1;
    marginleft: 4;
  }
}

.pointer-cursor {
  cursor: pointer !important;
}

.select {
  background-color: red;
  color: white;
}

@media screen and (max-width: 1024px) {
  .map-margin {
    margin-bottom: 200;
  }
}

.limited-text {
  max-width: 400px;
  max-height: 100px;
  overflow: hidden;
  word-wrap: break-word;
  transition: max-width 0.3s, max-height 0.3s;
}

.limited-text.expanded {
  max-width: 800px;
  max-height: 800px;
}

.message-text {
  /* No white-space style here */
}

.expanded-text {
  white-space: pre-line; /* Preserve spaces and line breaks when expanded */
}

.red-text {
  color: red;
  cursor: pointer;
}

/* .border-red-rounded {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid red;
  border-radius: 50%;
  padding: 10px;
  margin-bottom: 0.5rem;
  background-color: gray;
} */

.outer-border {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid red;
  border-radius: 50%;
  padding: 0px; /* Space for the inner white border */
  background-color: gray;
  margin-bottom: 0.5rem;
}

.inner-border {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid white;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-color: inherit; /* To maintain the same background as outer div */
}

.search-box {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
}

/* CardComponent.css */
.my-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card-body {
  flex: 1; /* This makes the body take up the available space */
}

.card-footer {
  /* No additional styling needed, but you can add some if desired */
}

/* Media query for non-mobile devices */
@media (min-width: 768px) {
  .my-card {
    height: 600px; /* Fixed height for desktops and larger tablets */
  }
}

.placeholder-black::placeholder {
  color: black;
}

/* Media query for desktops and larger screens */
/* @media (min-width: 992px) {
  .my-card {
    height: 600px; 
  }
} */

.containerThis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.item {
  flex: 1 1 50%;
  box-sizing: border-box;
  padding: 1rem;
}

.select-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.centered-text {
  text-align: center;
  justify-content: center;
}

.red-text {
  color: red;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .item {
    flex: 1 1 100%;
  }

  .select-container {
    margin-bottom: 1rem;
  }
}

/* .link {
  color: black;
  font-weight: 500;
  text-decoration: none;
  margin: 5px;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
  color: red; 
} */

.link {
  color: black;
  font-weight: 500;
  text-decoration: none;
  margin: 5px;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
  text-decoration-color: red; /* Optional: Set underline color */
  /* color: red; */
}

.divider {
  border: 0;
  height: 1px;
  background: #333;
  background-image: linear-gradient(to right, #ccc, #333, #ccc);
  margin: 20px 0; /* Adjust the margin to fit your layout */
}

.custom-modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the rgba values as needed */
}

.sub-navbar {
  background-color: #f8f9fa;
  padding: 10px 0;
  transition: top 0.3s;
  width: 100%;
  z-index: 1040;
}

.sub-navbar.fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1040;
}

.nav-input {
  width: 100px;
}

/* .react-select__menu {
  z-index: 1050;
}  */

/* .sub-navbar {
  height: 70px; 
} */

.sub-navbar .Select__menu {
  z-index: 2000 !important; /* Ensure dropdown is above the navbar */
}

.custom-textarea {
  position: relative;
  overflow: hidden;
}

.custom-textarea::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20px; /* Adjust the size as needed */
  height: 20px; /* Adjust the size as needed */
  background-color: #ffffff; /* Match the background color */
  z-index: 1;
}

.custom-placeholder::placeholder {
  color: black;
}
