.faq {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.faq-item {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.1em;
}

.icon {
  color: red;
  font-size: 1.5em;
}

.faq-answer {
  display: none;
  padding: 10px 0;
  color: #555;
}

.faq-answer.show {
  display: block;
}
